import React from "react"
import Content from "../Content"

import styled from "@emotion/styled"
import HH from "../atoms/logos/HH"
import { theme } from "../particles/theme"
import LEPAGE from "../atoms/logos/LEPAGE"
import { css } from "@emotion/core"
import { AiOutlineQuestionCircle, AiOutlineClose } from "react-icons/ai"
import { useState } from "react"
import Information from "./information"

const StyledHeader = styled.header`
  height: 150px;
  background-color: ${theme.colors.homeHardware.primary};

  @media (max-width: ${theme.breakpoints.tablet}) {
    height: unset;
  }

  .content {
    display: grid;
    display: -ms-flexbox;
    -ms-grid-columns: 1fr 6fr 1fr;
    grid-template-columns: 1fr 6fr 1fr;
    grid-gap: 2rem;
    padding-top: 3rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
      padding: 0 2rem;

      svg {
        color: white;
        font-size: 5rem;
        align-self: center;

        @media (max-width: ${theme.breakpoints.mobileSm}) {
          font-size: 3.5rem;
        }
      }

      a {
        align-self: center;
      }
    }

    @media (max-width: ${theme.breakpoints.mobileSm}) {
      display: flex;
    }

    h1 {
      font-family: "HelveticaNeue-CondensedBold", Helvetica, Arial, sans-serif;
      padding: 1rem;
      text-align: center;
      color: white;
      font-weight: 900;
      font-size: 60px;
      margin-top: 0;

      @media (max-width: ${theme.breakpoints.desktop}) {
        font-size: 50px;
      }

      @media (max-width: ${theme.breakpoints.tablet}) {
        margin-top: 33.5px;
        font-size: 40px;
      }

      @media (max-width: ${theme.breakpoints.mobileSm}) {
        margin-top: 33.5px;
        font-size: 25px;
      }
    }
  }
`

const lepageStyle = css`
  align-self: center;
  width: 200px;
  height: 200px;
  margin-top: -50px;

  @media (max-width: ${theme.breakpoints.tablet}) {
    width: 100px;
    height: 100px;
    margin-top: 5px;
  }

  @media (max-width: ${theme.breakpoints.mobileSm}) {
    width: 10vw;
    height: 10vw;
  }
`

const HHStyle = css`
  align-self: center;
  width: 125px;
  height: 125px;
  margin-top: -50px;

  @media (max-width: ${theme.breakpoints.tablet}) {
    width: 75px;
    height: 75px;
    margin-top: 5px;
  }

  @media (max-width: ${theme.breakpoints.mobileSm}) {
    width: 10vw;
    height: 10vw;
  }
`

const showHelp = css`
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    rgba(50, 50, 50, 0.5),
    rgba(50, 50, 50, 0.5)
  );

  section {
    position: absolute;
    z-index: 1000;
    top: 25vh;
    left: calc(50% - 35vw);
    width: 70vw;
    background: white;
    align-content: center;
    display: flex;
    box-shadow: 0px 0px 15px ${theme.colors.homeHardware.black};

    @media (max-width: ${theme.breakpoints.mobileMd}) {
      width: 90vw;
      left: calc(50% - 45vw);
      top: 15vh;
      padding: 0;
    }

    @media (max-width: ${theme.breakpoints.mobileSm}) {
      top: 5vh;
    }

    > div {
      align-self: center;

      ul li {
        margin-bottom: 0 !important;
      }
    }

    div.colored-rectangle {
      height: unset;
      width: unset;
      margin: 0;
    }

    p {
      margin: 0;
      width: 80%;
      margin: 0 auto;
    }
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }
`

const helpText = css`
  width: 100%;
  text-align: center;
  margin: 0 auto;

  h3 {
    margin-right: 1rem;
    font-size: 2.5rem;
    margin-top: 0;
    display: inline-block;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
  }

  svg {
    font-size: 3rem;
    position: relative;
    top: 0.5rem;
    color: white;
  }
`

const Header = () => {
  const [isOpen, setOpen] = useState(false)

  const handleChange = e => {
    if (
      e.target.classList.contains(`open`) ||
      e.target.classList.contains(`help-box`) ||
      e.target.tagName === `svg` ||
      e.target.tagName === `path`
    ) {
      setOpen(!isOpen)
      isOpen
        ? document.body.classList.remove(`no-scroll`)
        : document.body.classList.add(`no-scroll`)
    }
  }

  return (
    <>
      <StyledHeader>
        <Content className="content">
          <HH css={HHStyle} />

          <h1>COLOUR MATCH TOOL</h1>
            <a href="https://www.lepage.ca">
              <LEPAGE className="far-right" css={lepageStyle} />
            </a>
        </Content>
        <div
          className={isOpen ? `open` : `closed`}
          css={showHelp}
          onClick={handleChange}
          onKeyPress={handleChange}
          role="button"
          tabIndex={0}
        >
          <section>
            <label>
              <button
              style={{
                cursor: `pointer`,
                position: `absolute`,
                top: `1rem`,
                right: `1rem`,
                fontSize: `2rem`,
                textTransform: `uppercase`,
                zIndex: `1003`,
                background: `white`,
                border: `none`
              }}
              className="help-box"
              onClick={handleChange}
              onKeyPress={handleChange}
              tabIndex={0}
            >
              Close{" "}
              <AiOutlineClose
                style={{
                  fontSize: `3rem`,
                  position: `relative`,
                  top: `.75rem`,
                }}
              />
              </button>
            </label>
            <Information />
          </section>
        </div>
        <section
          className="display-mobile help-box"
          onClick={handleChange}
          onKeyPress={handleChange}
          css={helpText}
          role="button"
          tabIndex={0}
        >
          <h3 className="help-box">How to use this tool</h3>
          <AiOutlineQuestionCircle
            style={{ cursor: `pointer` }}
            className="display-mobile"
          />
        </section>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="96676a8e-3409-4bbb-a355-1265f842f302" ></script>
        <script type="text/javascript" dangerouslySetInnerHTML={{__html: 'function OptanonWrapper() { }'}}></script>
        <script dangerouslySetInnerHTML={{__html: `document.addEventListener("DOMContentLoaded", function (event) {var cookieSettingsBtn = document.getElementById('ot-sdk-btn');if (cookieSettingsBtn) {cookieSettingsBtn.addEventListener("click", function (e) {Optanon.ToggleInfoDisplay();e.preventDefault();})}})`}}></script>
      </StyledHeader>
    </>
  )
}

export default Header
